import React, { FunctionComponent, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";

import {
  BaseSelect,
  BaseDropdown,
  BaseForm,
  CurrencyInput,
  BaseFieldSet,
  EmailInput,
  BaseInput,
  FilterOperator,
  StateInput,
  DateInput,
} from "react-epharma-components";
import DateRangeInput from "react-epharma-components/lib/components/input/date-range";
import { First } from "react-bootstrap/PageItem";

const CrudForm: FunctionComponent = (props) => {
  const id = React.createRef<any>();
  const chain = React.createRef<any>();
  const client = React.createRef<any>();
  const benefit = React.createRef<any>();
  const product = React.createRef<any>();
  const presentation = React.createRef<any>();
  const startDate = React.createRef<any>();
  const endDate = React.createRef<any>();
  const corporateName = React.createRef<any>();
  const tradeName = React.createRef<any>();
  const nameBenefit = React.createRef<any>();
  const commisionValue = React.createRef<any>();
  const inclusionDate = React.createRef<any>();  
  const creationUsername = React.createRef<any>(); 

  const _this = React.createRef<BaseForm>();

  const inputRefs = [
    id,
    product,
    presentation,
    startDate,
    endDate,
    corporateName,
    tradeName,
    nameBenefit,
    commisionValue,
    inclusionDate,
    creationUsername,
  ];

  const basechainEndpoint = window.chain.base; //Rede
  const baseclientEndpoint = window.client.base; //Cliente
  const baseproductEndpoint = window.product.base; //Produto
  
  const chainEndpoint = basechainEndpoint + window.chain.chain; //Rede
  const clientEndpoint = baseclientEndpoint + window.client.client; //Cliente
  const benefitEndpoint = baseclientEndpoint + window.client.benefit; //Plano (Benefício)
  const productEndpoint =  baseproductEndpoint + window.product.product; //Produto
  const presentationEndpoint = baseproductEndpoint + window.product.presentation; //Apresentação
  const commisionEndpoint = basechainEndpoint + window.chain.comissionChain; //Comissão
  
  useEffect(() => {
    console.log(_this.current?.baseForm);
  }, [_this.current]);

  return (
    <BaseForm refs={inputRefs} {...props} ref={_this}>
      <BaseFieldSet legend="" collapsible={false}>
        <Row>
          <BaseInput name="id" ref={id} identifier hidden />
          <Col xs="12" sm="12" md="6" lg="6" xl="4">            
            <BaseDropdown
              name="chainId"
              label="Rede"
              type="string"
              labelProperty="corporateName" 
              valueProperty="id"
              ref={corporateName}
              endpoint={chainEndpoint}
              required
              identifier
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="4">
            <BaseDropdown
              name="clientId"
              label="Cliente"
              type="string"             
              labelProperty="tradeName"
              valueProperty="id"           
              ref={tradeName}
                childField={nameBenefit}
                childFieldProperty="clientId"
              endpoint={clientEndpoint}              
              identifier
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="4">
            <BaseDropdown
              name="benefitId"
              label="Plano (Benefício)"
              type="string"
              labelProperty="name"              
              ref={nameBenefit}
              endpoint={benefitEndpoint}
              identifier
            />
          </Col>
        </Row>
        <Row>
        <Col xs="12" sm="12" md="6" lg="6" xl="4">
            <BaseDropdown
              name="productId"
              label="Produto"
              type="string"
              labelProperty="name"
              valueProperty="id"              
              ref={product}
               childField={presentation}
               childFieldProperty="productId"
              endpoint={productEndpoint}
              identifier
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="4">
            <BaseDropdown
              name="presentationId"
              label="Apresentação"
              type="string"
              labelProperty="name"              
              ref={presentation}
              endpoint={presentationEndpoint}
              identifier
            />
          </Col>
          <Col xs="8" sm="8" md="4" lg="4" xl="4">
            <CurrencyInput
              name="commisionValue"
              label="% de Comissão"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={commisionValue}
              identifier
            />
          </Col>
       </Row>
        <Row>
          <DateRangeInput 
            startDate={{
              name: "startDate",
              label: "Início",
              type: "Datetime",              
              forwardedRef: startDate,
              col: {
                xs: 4,
                sm: 12,
                md: 4,
              },
            }}
            endDate={{
              name: "endDate",
              label: "Fim",              
              type: "Datetime",
              forwardedRef: endDate,
              max: startDate.current?.value + 1,
              col: {
                xs: 12,
                sm: 12,
                md: 4,
              },
            }}                    
          />          
        </Row>   
      </BaseFieldSet>
    </BaseForm>
  );
};

export default CrudForm;
