import React from "react";
import { ICrud, ITableColumn, BaseGrid, BaseToolbar, BaseScreen, CNPJ_MASK} from "react-epharma-components";
import CrudForm from "./Crud";
import SearchForm from "./Search";

export default class AssociationChainScreen extends React.Component {
  private columns: ITableColumn[] = [
    { name: "id", displayName: "Id", sortable: true, width: "2%"},
    { name: "chainId", displayName: "Rede", sortable: true, width: "15%", onRender: (value, model) => { return `${model.chainId} / ${model.chain.corporateName}`;},},
    { name: "clientId", displayName: "Cliente", sortable: true, width: "15%", onRender: (value, model) => { return value > 0 ? `${model.client.id} / ${model.client.tradeName}` : ``; },},
    { name: "benefitId", displayName: "Plano (Benefício)", sortable: true, width: "15%", onRender: (value, model) => { return value > 0 ? `${model.benefit.id} / ${model.benefit.name}` : ``; },},
    { name: "productId", displayName: "Produto", sortable: true, width: "15%", onRender: (value, model) => { return value > 0 ? `${model.product.id} / ${model.product.name}` : ``; },},
    { name: "presentationId", displayName: "Apresentação", sortable: true, width: "15%", onRender: (value, model) => { return value > 0 ? `${model.presentation.id} / ${model.presentation.name}` : ``; },},
    { name: "commisionValue", displayName: "% Comissão", sortable: true, width: "7%", onRender:(value)=>{ return value?.toString().replace('.', ',');},},
    { name: "startDate", displayName: "Início da Comissão", sortable: true, width: "10%", type: "datetime" },
    { name: "endDate", displayName: "Fim da Comissão", sortable: true, width: "10%", type: "datetime" },
  ];

  private crud: ICrud = {
    form: <CrudForm />,
    create: {
      show: true
    },
    read: {
      show: true,
    },
    log: {
        show: true,
        id: 4,
        keys: "chainId|id|clientId|benefitId|productId|presentationId"
    },
    update: {
      show: true,
    },    
  };

  render() {
    const baseEndpoint =
      window.chain.base +
      window.chain.comissionChain;

    return (
      <BaseScreen title="" description="">
        <BaseGrid
          name="Search"
          baseEndpoint={baseEndpoint}
          toolbar={
            <BaseToolbar
              crud={this.crud}
              search={{
                show: true,
                form: <SearchForm />,
              }}

            />
          }
          columns={this.columns}
          {...this.props}
        />
      </BaseScreen>
    );
  }
}
