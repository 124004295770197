import React from "react";
import AssociationChainScreen from './association-chain/Panel';
import {BaseScreen} from "react-epharma-components";

export default class AssociationChain extends React.Component {
    state = {
        data: []
    }
    
    onSelect = (data: any[]) => {
        this.setState({ 
            data
        });
    };
    render() {
        return (
            <BaseScreen 
                title="% Comissão Rede x Cliente" 
                description="" 
            >                
            <AssociationChainScreen></AssociationChainScreen>
            </BaseScreen>
        )
    }
}