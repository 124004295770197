import React, { FunctionComponent } from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseForm,
  BaseFieldSet,
  BaseDropdown,
  FilterOperator,
  CnpjInput,
  CurrencyInput,
  DateRangeInput,
} from "react-epharma-components";

const SearchForm: FunctionComponent = (props) => {
  const product = React.createRef<any>();
  const client = React.createRef<any>();
  const benefit = React.createRef<any>();
  const form = React.createRef<BaseForm>();
  const chain = React.createRef<any>();
  const corporateName = React.createRef<any>();
  const cnpj = React.createRef<any>();
  const tradeName = React.createRef<any>();
  const commisionValue = React.createRef<any>();
  const startDate = React.createRef<any>();
  const endDate = React.createRef<any>();


  const basechainEndpoint = window.chain.base; //Rede
  const baseclientEndpoint = window.client.base; //Cliente
  const baseproductEndpoint = window.product.base; //Produto

  const chainEndpoint = basechainEndpoint + window.chain.chain; //Rede
  const clientEndpoint = baseclientEndpoint + window.client.client; //Cliente
  const benefitEndpoint = baseclientEndpoint + window.client.benefit; //Plano (Benefício)
  const productEndpoint = baseproductEndpoint + window.product.product; //Produto
  const presentationEndpoint = baseproductEndpoint + window.product.presentation; //Apresentação
  const commisionEndpoint = basechainEndpoint + window.chain.comissionChain; //Comissão

  return (
    <BaseForm ref={form} refs={[product, client, benefit, chain, corporateName, tradeName, cnpj, commisionValue, startDate, endDate,]} {...props}>
      <BaseFieldSet legend="Filtros">
        <Row>
          <Col xs="10" sm="10" md="4" lg="4" xl="4">
            <BaseDropdown
              name="chainId"
              label="Rede"
              type="string"
              endpoint={chainEndpoint}
              labelProperty="corporateName"
              valueProperty="id"
              ref={corporateName}
              filterOperator={FilterOperator.Equals}
            />
          </Col>
          <Col xs="8" sm="8" md="4" lg="4" xl="4">
            <CnpjInput
              name="chain.cnpj"
              type="text"
              ref={cnpj}
            />
          </Col>
          <Col xs="10" sm="10" md="4" lg="4" xl="4">
            <BaseDropdown
              name="clientId"
              label="Cliente"
              type="string"
              endpoint={clientEndpoint}
              labelProperty="tradeName"
              valueProperty="id"
              ref={tradeName}
              filterOperator={FilterOperator.Equals}
            />
          </Col>
          <Col xs="8" sm="8" md="4" lg="4" xl="4">
            <CurrencyInput
              name="commisionValue"
              label="% de Comissão"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={commisionValue}
            />
          </Col>
          <Col>
            <Row>
              <DateRangeInput
                startDate={{
                  name: "startDate",
                  label: "Início",
                  type: "Datetime",
                  forwardedRef: startDate,
                  col: {
                    xs: 4,
                    sm: 12,
                    md: 4,
                  },
                }}
                endDate={{
                  name: "endDate",
                  label: "Fim",
                  type: "Datetime",
                  forwardedRef: endDate,
                  max: startDate.current?.value + 1,
                  col: {
                    xs: 12,
                    sm: 12,
                    md: 4,
                  },
                }}
              />
            </Row>
          </Col>
        </Row>
      </BaseFieldSet>
    </BaseForm>
  );
};

export default SearchForm;
